import React from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Make sure your CSS is properly imported

function CareersPage() {
  return (
    <div className="careers-page">
      <h1>Careers at Plix</h1>
      <p className="career-description">
        If you like hacking on massive scale computer vision systems, embedded
        systems, and want to be part of building a legendary company,
        <br />
        <br />
        <strong>
          Please reach out to{" "}
          <a href="mailto:careers@plix.ai" className="email-link">
            careers@plix.ai
          </a>
        </strong>
      </p>

      <div className="careers-button-container">
        <a
          href="https://jobs.gem.com/plixai"
          target="_blank"
          rel="noopener noreferrer"
          className="careers-button"
        >
          View Open Positions
        </a>
      </div>

      <footer className="footer">
        <Link to="/" className="home-button">
          <i className="fas fa-arrow-left"></i>
        </Link>
      </footer>
    </div>
  );
}

export default CareersPage;
